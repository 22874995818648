export default function compatiblePPOffer(rule) {
  let compatibleProduct = rule.product
  /**
   * We need to change the existing offer's state to match the new state
   */
  if (rule.product && !Array.isArray(rule.product)) {
    // the offer only has one upsell product, so all the settings are in the root level
    const productItem = {
      product: rule.product,
      variants: rule.variants,
      description: rule.description,
      featuredImage: rule.featuredImage,
      discount: rule.discount,
      discountMessage: rule.discountMessage,
      qtyLimit: rule.qtyLimit,
      hideQTY: rule.hideQTY,
      despBold: rule.despBold,
      despTextAlign: rule.despTextAlign,
      despTextSize: rule.despTextSize,
    }
    const offerSetting = {
      banner: rule.banner,
      expiration: rule.expiration,
      bannerBold: rule.bannerBold,
      bannerTextAlign: rule.bannerTextAlign,
      bannerTextSize: rule.bannerTextSize,
      shippingText: rule.shippingText,
    }
    compatibleProduct = [{ products: [productItem], ...offerSetting }]
  } else if (
    rule.product &&
    Array.isArray(rule.product) &&
    rule.product.length > 0 &&
    !rule.product[0].products
  ) {
    // the offer has upsell and downsell products, but they only have one product each, so all the settings are in the product array level
    compatibleProduct = rule.product.map((item, index) => {
      const productItem = {
        product: item.product,
        variants: item.variants,
        description: item.description,
        featuredImage: item.featuredImage,
        discount: item.discount,
        discountMessage: item.discountMessage,
        qtyLimit: item.qtyLimit,
        hideQTY: item.hideQTY,
        despBold: item.despBold,
        despTextAlign: item.despTextAlign,
        despTextSize: item.despTextSize,
      }
      const offerSetting = {
        banner: item.banner,
        expiration: item.expiration,
        bannerBold: item.bannerBold,
        bannerTextAlign: item.bannerTextAlign,
        bannerTextSize: item.bannerTextSize,
        shippingText: item.shippingText,
      }
      if (index === 1) {
        offerSetting["shownWhenFirst"] = "both" === item.shownWhenFirst ? "denied" : item.shownWhenFirst
      }
      return { products: [productItem], ...offerSetting }
    })
  } else if (!rule.product) {
    rule["product"] = [
      {
        expiration: 15,
        products: [
          {
            discount: { sym: "$", value: 0 },
          },
        ],
        productsPerRow: 1,
      },
    ]
    compatibleProduct = [
      {
        expiration: 15,
        products: [
          {
            discount: { sym: "$", value: 0 },
          },
        ],
        productsPerRow: 1,
      },
    ]
  } else if (
    rule.product &&
    Array.isArray(rule.product) &&
    rule.product.length === 2 &&
    Array.isArray(rule.product[1].products)
  ) {
    // the offer has upsell and downsell products, and downsell have multiple products, we'll change both to denied because both is deprecated
    compatibleProduct = rule.product.map((item, index) => {
      if (index === 1) {
        return { ...item, shownWhenFirst: "both" === item.shownWhenFirst ? "denied" : item.shownWhenFirst}
      }else{
        return item
      }
    })
  }
  return { ...rule, product: compatibleProduct }
}
